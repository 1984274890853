import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{attrs:{"cols":"8","sm":"6"}},[_c(VCardTitle,{staticClass:" d-flex flex-nowrap "},[_c('span',{staticClass:"text-no-wrap"},[_vm._v("Dashboard")])]),_c(VCardText,[_c(VSelect,{staticClass:"mb-3",attrs:{"multiple":"","hide-details":"auto","outlined":"","dense":"","items":_vm.channels,"item-text":"displayName","item-value":"idNipKanal","label":"Kanäle"},on:{"change":_vm.updateFilter},model:{value:(_vm.filterCopy.idNipKanalListe),callback:function ($$v) {_vm.$set(_vm.filterCopy, "idNipKanalListe", $$v)},expression:"filterCopy.idNipKanalListe"}})],1)],1),_c(VCol,{attrs:{"cols":"4","sm":"6"}},[_c('div',[_c(VImg,{staticClass:"gamification-tree-4",attrs:{"width":"120","src":require("@/assets/images/misc/tree-4.png")}}),_c(VImg,{staticClass:"gamification-john-pose-2",attrs:{"width":"111","src":require("@/assets/images/3d-characters/pose-2.png")}}),_c(VImg,{staticClass:"gamification-tree",attrs:{"width":"100","src":require("@/assets/images/misc/tree.png")}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }