<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="align-self-end"
      >
        <dashboard-filter :filter="filter" @updateFilter="updateFilter"></dashboard-filter>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col
        cols="12"
        md="3"
        sm="6"
        order="1"
        class="align-self-end"
      >
        <dashboard-card-clicks :filter="filter"></dashboard-card-clicks>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
        order="2"
        class="align-self-end"
      >
        <dashboard-card-abos :filter="filter"></dashboard-card-abos>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="3"
        class="align-self-end"
      >
        <dashboard-card-profit :filter="filter"></dashboard-card-profit>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="6"
        order="4"
        class="align-self-end"
      >
        <dashboard-card-opt-outs :filter="filter"></dashboard-card-opt-outs>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <dashboard-chart-clicks :filter="filter" class="fill-height"></dashboard-chart-clicks>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <dashboard-chart-expansion :filter="filter" class="fill-height"></dashboard-chart-expansion>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <dashboard-total-overview :filter="filter" class="fill-height"></dashboard-total-overview>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import {onUnmounted, onMounted, ref, getCurrentInstance} from '@vue/composition-api'

import DashboardFilter from '@/views/dashboard/DashboardFilter'
import DashboardCardClicks from '@/views/dashboard/DashboardCardClicks'
import DashboardCardAbos from '@/views/dashboard/DashboardCardAbos'
import DashboardCardProfit from '@/views/dashboard/DashboardCardProfit'
import DashboardCardOptOuts from '@/views/dashboard/DashboardCardOptOuts'
import DashboardChartClicks from '@/views/dashboard/DashboardChartClicks'
import DashboardChartExpansion from '@/views/dashboard/DashboardChartExpansion'
import DashboardTotalOverview from '@/views/dashboard/DashboardTotalOverview'

import store from '@/store'
import {
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline
} from '@mdi/js'

import userStoreModule from '@/views/account/userStoreModule'
import axios from "axios";

export default {
  components: {
    DashboardFilter,
    DashboardCardClicks,
    DashboardCardAbos,
    DashboardCardProfit,
    DashboardCardOptOuts,
    DashboardChartClicks,
    DashboardChartExpansion,
    DashboardTotalOverview,
  },
  setup() {
    const loading = ref(true)
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    const filter = ref({
      idNipKanalListe:[]
    })

    const updateFilter = (newFilter) => {
      filter.value = newFilter;
    };

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    return {
      filter,
      updateFilter,
      icons: {
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

#foto{
  display:none
}
.uploadImage{
  cursor: pointer;
}

</style>
