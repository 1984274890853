<template>
  <v-card>
    <v-row class="ma-0 pa-0">
      <v-col
        cols="8"
        sm="6"
      >
        <v-card-title class=" d-flex flex-nowrap ">
          <span class="text-no-wrap">Dashboard</span>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="filterCopy.idNipKanalListe"
            @change="updateFilter"
            class="mb-3"
            multiple
            hide-details="auto"
            outlined
            dense
            :items="channels"
            item-text="displayName"
            item-value="idNipKanal"
            label="Kanäle"
          ></v-select>
        </v-card-text>
      </v-col>
      <v-col
        cols="4"
        sm="6"
      >
        <div>
          <v-img
            width="120"
            src="@/assets/images/misc/tree-4.png"
            class="gamification-tree-4"
          ></v-img>
          <v-img
            width="111"
            src="@/assets/images/3d-characters/pose-2.png"
            class="gamification-john-pose-2"
          ></v-img>
          <v-img
            width="100"
            src="@/assets/images/misc/tree.png"
            class="gamification-tree"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="scss" scoped>
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
</style>

<script>

import {onMounted, ref, watch} from "@vue/composition-api"
import axios from "axios";

export default {
  props: {
    filter: {
      type: Object,
      default: () => {idNipKanalListe:[]}
    }
  },
  setup(props,{emit}){

    const filterCopy = ref({});
    filterCopy.value = JSON.parse(JSON.stringify(props.filter));
    watch(() => props.filter, (newValue) => {
      filterCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const updateFilter = () => {
      emit("updateFilter",filterCopy.value)
    }

    const channels = ref([]);
    onMounted(()=> {
      axios.get('/api/ajaxServer/?aktion=fetchChannels').then((response) => channels.value = response.data.channels)
    })

    return {
      updateFilter,
      filterCopy,
      channels
    }
  }
}
</script>
